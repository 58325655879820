<template>
    <h-row>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Tipo"
                                      key-options="isPreventivo"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'DEFINITIVO', value: false},
                                          {label: 'PREVENTIVO', value: true}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Stato"
                                      key-options="errore"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'ATTIVO', value: 'NONE'},
                                          {label: 'INATTIVO', value: 'NOT_NONE'},
                                          {label: 'PREVENTIVO', value: 'PREVENTIVO'},
                                          {label: 'NON ANCORA INIZIATO', value: 'NO_STARTED'},
                                          {label: 'EVENTO TERMINATO', value: 'EXPIRED'}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12" sm="6">
            <base-select-clienti v-model="cliente"
                                 @input="$emit('changeOptions', {idClienteFk: cliente ? cliente.ID_REC : null})"
            ></base-select-clienti>
        </h-col>

        <h-col cols="12" sm="6">
            <base-table-header-filter exact
                                      @changeFilter="$emit('changeFilter', $event)"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-filter>
        </h-col>

    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTableHeaderSelect from '@/components/aaaProject/table/BaseTableHeaderSelect';
    import BaseTableHeaderFilter from '@/components/aaaProject/table/BaseTableHeaderFilter';
    import BaseSelectClienti from '@/components/aaaProject/table/api/BaseSelectClienti';

    export default {
        name: 'ContrattiTableHeader',
        components: {HRow, HCol, BaseTableHeaderFilter, BaseTableHeaderSelect, BaseSelectClienti},
        data: () => ({
            cliente: undefined
        })
    }
</script>

<style scoped>

</style>
