var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"contratti","model-ref":"il contratto","new-msg":"un nuovo contratto","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.state.authModule.utente.CAN_CREATE_CONTRATTO},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('contratti-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"IS_PREVENTIVO",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("fa fa-"+_vm._s(!item.IS_PREVENTIVO ? 'check' : 'triangle-exclamation'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(!item.IS_PREVENTIVO ? 'DEFINITIVO' : 'PREVENTIVO'))])])]}},{key:"DATA_INIZIO",fn:function(ref){
var item = ref.item;
return [_c('i',{class:!!item.DATA_INIZIO && (_vm.getData(new Date(), 'yyyyMMDD') < _vm.getData(item.DATA_INIZIO, 'yyyyMMDD') ) ? 'error--text' : undefined},[_vm._v(" "+_vm._s(!item.DATA_INIZIO ? '-' : _vm.getData(item.DATA_INIZIO))+" ")])]}},{key:"DATA_SCADENZA",fn:function(ref){
var item = ref.item;
return [_c('i',{class:!!item.DATA_SCADENZA && (_vm.getData(new Date(), 'yyyyMMDD') > _vm.getData(item.DATA_SCADENZA, 'yyyyMMDD') ) ? 'error--text' : undefined},[_vm._v(" "+_vm._s(!item.DATA_SCADENZA ? '-' : _vm.getData(item.DATA_SCADENZA))+" ")])]}},{key:"TOT_EVENTI",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","x-small":"","color":"primary","text":'' + item.TOT_EVENTI,"to":'/secure/eventi?cln=' + item.ID_CLIENTE_FK,"exact":""}},[_c('h-row',[_c('h-col',{staticClass:"py-0 px-6"},[_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" XS: "+_vm._s(item.EV_XS)+" ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" S: "+_vm._s(item.EV_S)+" ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" M: "+_vm._s(item.EV_M)+" ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" L: "+_vm._s(item.EV_L)+" ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" XL: "+_vm._s(item.EV_XL)+" ")])]),_c('h-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(" XXL: "+_vm._s(item.EV_XXL)+" ")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }