var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h-row',[_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Tipo","key-options":"isPreventivo","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'DEFINITIVO', value: false},
                                      {label: 'PREVENTIVO', value: true}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Stato","key-options":"errore","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: 'ATTIVO', value: 'NONE'},
                                      {label: 'INATTIVO', value: 'NOT_NONE'},
                                      {label: 'PREVENTIVO', value: 'PREVENTIVO'},
                                      {label: 'NON ANCORA INIZIATO', value: 'NO_STARTED'},
                                      {label: 'EVENTO TERMINATO', value: 'EXPIRED'}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-select-clienti',{on:{"input":function($event){return _vm.$emit('changeOptions', {idClienteFk: _vm.cliente ? _vm.cliente.ID_REC : null})}},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-filter',{attrs:{"exact":""},on:{"changeFilter":function($event){return _vm.$emit('changeFilter', $event)},"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }