<template>
    <base-table models-name="contratti"
                model-ref="il contratto"
                new-msg="un nuovo contratto"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.state.authModule.utente.CAN_CREATE_CONTRATTO"
                @changeOptions="onChangeOptions($event)"
    >

        <template #header="{ onChangeFilter }">
            <contratti-table-header @changeOptions="onChangeOptions($event)"
                                    @changeFilter="onChangeFilter"
            ></contratti-table-header>
        </template>

        <template #IS_PREVENTIVO="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>fa fa-{{ !item.IS_PREVENTIVO ? 'check' : 'triangle-exclamation' }}</v-icon>
                </template>
                <span>{{ !item.IS_PREVENTIVO ? 'DEFINITIVO' : 'PREVENTIVO' }}</span>
            </v-tooltip>
        </template>

        <template #DATA_INIZIO="{ item }">
            <i :class="!!item.DATA_INIZIO && (getData(new Date(), 'yyyyMMDD') < getData(item.DATA_INIZIO, 'yyyyMMDD') ) ? 'error--text' : undefined">
                {{ !item.DATA_INIZIO ? '-' : getData(item.DATA_INIZIO) }}
            </i>
        </template>

        <template #DATA_SCADENZA="{ item }">
            <i :class="!!item.DATA_SCADENZA && (getData(new Date(), 'yyyyMMDD') > getData(item.DATA_SCADENZA, 'yyyyMMDD') ) ? 'error--text' : undefined">
                {{ !item.DATA_SCADENZA ? '-' : getData(item.DATA_SCADENZA) }}
            </i>
        </template>

        <template #TOT_EVENTI="{ item }">
            <button-tooltip fab
                            x-small
                            color="primary"
                            :text="'' + item.TOT_EVENTI"
                            :to="'/secure/eventi?cln=' + item.ID_CLIENTE_FK"
                            exact
            >
                <h-row>
                    <h-col class="py-0 px-6">
                        <h-row justify="center">
                            <span>
                                XS: {{ item.EV_XS }}
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                S: {{ item.EV_S }}
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                M: {{ item.EV_M }}
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                L: {{ item.EV_L }}
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                XL: {{ item.EV_XL }}
                            </span>
                        </h-row>
                        <h-row justify="center">
                            <span>
                                XXL: {{ item.EV_XXL }}
                            </span>
                        </h-row>
                    </h-col>
                </h-row>
            </button-tooltip>
        </template>

    </base-table>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import ContrattiTableHeader from '@/components/secure/contratti/components/ContrattiTableHeader';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import moment from '@/moment';

    export default {
        name: 'ContrattiTable',
        components: {HRow, HCol, ButtonTooltip, ContrattiTableHeader, BaseTable},
        props: {isLoading: Boolean},
        data: () => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Stato ',
                    value: 'ERRORE',
                    align: 'center'
                },
                {
                    text: 'Tipo ',
                    value: 'IS_PREVENTIVO',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Cliente ',
                    value: 'CL_NOME',
                    align: 'center'
                },
                {
                    text: 'Nome ',
                    value: 'NOME',
                    align: 'center'
                },
                {
                    text: 'Inizio ',
                    value: 'DATA_INIZIO',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Scadenza ',
                    value: 'DATA_SCADENZA',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Eventi Acquistati ',
                    value: 'TOT_EVENTI',
                    align: 'center',
                    custom: true
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getData(data, format) {
                return moment.toDate(data, !format ? 'L' : format);
            }
        }
    }
</script>

<style scoped>

</style>
